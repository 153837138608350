import React from "react";

import Layout from "../components/Layout";
import SEO from "../components/SEO";

const NewsletterPage: React.FC = () => {
    return (
        <Layout>
            <SEO title="Email Newsletter" />

            <section className="section">
                <div className="container content">
                    <h1 className="title is-1">Email Newsletter</h1>
                    <p>
                        To subscribe to our email newsletter, please click the
                        link below.
                    </p>
                    <a
                        className="button is-primary is-large"
                        href="http://eepurl.com/bCCbYn"
                        target="_blank"
                        rel="nofollow noreferrer noopener"
                    >
                        Subscribe
                    </a>
                </div>
            </section>
        </Layout>
    );
};

export default NewsletterPage;
